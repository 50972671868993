import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto"; // Import Chart.js
import { db } from "../firebase";

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${day}/${month}/${year}`;
};

const calculateOrderAmount = (items) => {
  return items.reduce((total, item) => total + parseFloat(item.rate || 0), 0);
};

const Indiamart = () => {
  const [gatePasses, setGatePasses] = useState([]);
  const [orderChartData, setOrderChartData] = useState({});
  const [salesChartData, setSalesChartData] = useState({});
  const [orderChartInstance, setOrderChartInstance] = useState(null);
  const [salesChartInstance, setSalesChartInstance] = useState(null);
  const [totalSaleThisMonth, setTotalSaleThisMonth] = useState(0); // Moved useState inside the functional component

  useEffect(() => {
    const fetchGatePasses = async () => {
      try {
        const snapshot = await db.collection("gatePasses").get();
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          date: formatDate(doc.data().date.seconds),
          orderAmount: calculateOrderAmount(doc.data().items),
        }));

        // Sort gate passes by GP number in descending order
        const sortedGatePasses = data.sort((a, b) => b.GPNo - a.GPNo);

        setGatePasses(sortedGatePasses);

        // Calculate total sale for this month
        calculateTotalSaleThisMonth(sortedGatePasses);

        // Fetch the initial date from Firestore for the selected gate pass
        createChartData(sortedGatePasses); // Call createChartData with the data
      } catch (error) {
        console.error("Error fetching gate passes:", error);
      }
    };

    fetchGatePasses();
  }, []);

  const createMonthlyOrderList = (data) => {
    const monthlyOrders = {
      "01": { orderAmount: 0, orderCount: 0 }, // January
      "02": { orderAmount: 0, orderCount: 0 }, // February
      "03": { orderAmount: 0, orderCount: 0 }, // March
      "04": { orderAmount: 0, orderCount: 0 }, // April
      "05": { orderAmount: 0, orderCount: 0 }, // May
      "06": { orderAmount: 0, orderCount: 0 }, // June
      "07": { orderAmount: 0, orderCount: 0 }, // July
      "08": { orderAmount: 0, orderCount: 0 }, // August
      "09": { orderAmount: 0, orderCount: 0 }, // September
      10: { orderAmount: 0, orderCount: 0 }, // October
      11: { orderAmount: 0, orderCount: 0 }, // November
      12: { orderAmount: 0, orderCount: 0 }, // December
    };

    data.forEach((gatePass) => {
      const month = gatePass.date.substring(3, 5);
      monthlyOrders[month].orderAmount += gatePass.orderAmount;
      monthlyOrders[month].orderCount++;
    });

    return monthlyOrders;
  };

  const createChartData = (data) => {
    // Prepare data for the charts
    const dailyData = {};
    const salesAmount = [];
    data.forEach((gatePass) => {
      const month = gatePass.date.substring(3, 5);
      const day = gatePass.date.substring(0, 2);

      // Daily data
      if (dailyData[gatePass.date]) {
        dailyData[gatePass.date]++;
      } else {
        dailyData[gatePass.date] = 1;
      }

      // Sales amount data
      salesAmount.push(gatePass.orderAmount);
    });

    // Combine daily and monthly data for the charts
    const orderChartData = {
      labels: Object.keys(dailyData),
      data: Object.values(dailyData),
    };

    const salesChartData = {
      labels: Object.keys(dailyData),
      data: salesAmount,
    };

    setOrderChartData(orderChartData);
    setSalesChartData(salesChartData);
  };

  useEffect(() => {
    if (orderChartData.labels && orderChartData.labels.length > 0) {
      if (orderChartInstance) {
        orderChartInstance.destroy();
      }
      renderOrderChart(orderChartData);
    }
  }, [orderChartData]);

  useEffect(() => {
    if (salesChartData.labels && salesChartData.labels.length > 0) {
      if (salesChartInstance) {
        salesChartInstance.destroy();
      }
      renderSalesChart(salesChartData);
    }
  }, [salesChartData]);

  const renderOrderChart = (data) => {
    const ctx = document.getElementById("orderChart").getContext("2d");
    const newChartInstance = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.labels,
        datasets: [
          {
            label: "Daily Orders",
            data: data.data,
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Daily Orders",
            },
          },
        },
      },
    });
    setOrderChartInstance(newChartInstance);
  };

  const renderSalesChart = (data) => {
    const ctx = document.getElementById("salesChart").getContext("2d");
    const newChartInstance = new Chart(ctx, {
      type: "line",
      data: {
        labels: data.labels,
        datasets: [
          {
            label: "Sales Amount",
            data: data.data,
            borderColor: "rgba(54, 162, 235, 0.6)",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Sales Amount",
            },
          },
        },
      },
    });
    setSalesChartInstance(newChartInstance);
  };

  const calculateTotalSaleThisMonth = (data) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // January is 0, so add 1
    const currentYear = currentDate.getFullYear();

    const totalSale = data.reduce((total, gatePass) => {
      const gatePassDate = new Date(gatePass.date);
      const gatePassMonth = gatePassDate.getMonth() + 1; // January is 0, so add 1
      const gatePassYear = gatePassDate.getFullYear();

      if (gatePassMonth === currentMonth && gatePassYear === currentYear) {
        return total + gatePass.orderAmount;
      } else {
        return total;
      }
    }, 0);

    setTotalSaleThisMonth(totalSale);
  };

  const totalOrders = gatePasses.length;
  const totalSale = gatePasses.reduce((total, gatePass) => {
    return total + gatePass.orderAmount;
  }, 0);

  const averageSalePerOrder = totalOrders === 0 ? 0 : totalSale / totalOrders;
  const monthlyOrderList = createMonthlyOrderList(gatePasses);

  return (
    <div className="bg-white">
      {/* <h1>Indiamart</h1> */}
      <div className="monthly-order-list p-5 w-50">
        <div className="card">
          <div className="card-body">
            <h2>Monthly Order and Sale Amounts</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Order Amount</th>
                  <th>Sale Amount</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(monthlyOrderList).map(
                  ([month, { orderAmount, orderCount }]) => (
                    <tr key={month}>
                      <td>Month {month}</td>
                      <td>{orderAmount.toFixed(2)}</td>
                      <td>{orderCount}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="d-flex gap-5 p-3">
          <div className="card shadow-sm">
            <div className="card-body">
              <canvas id="orderChart" width="700" height="400"></canvas>
            </div>
          </div>
          <div className="card shadow-sm">
            <div className="card-body">
              <canvas id="salesChart" width="700" height="400"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="sale-card m-3 shadow-lg rounded">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Total Sale This Month</h5>
              <p className="card-text">{totalSaleThisMonth.toFixed(2)} INR</p>
            </div>
          </div>
        </div>
        <div className="sale-card m-3 shadow-lg rounded">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Average Sale Per order</h5>
              <p className="card-text">{averageSalePerOrder.toFixed(2)} INR</p>
            </div>
          </div>
        </div>
      </div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>GP Number</th>
            <th>Party Name</th>
            <th>Date</th>
            <th>Order Amount</th>
          </tr>
        </thead>
        <tbody>
          {gatePasses.map((gatePass) => (
            <tr key={gatePass.id}>
              <td>{gatePass.GPNo}</td>
              <td>{gatePass.partyName}</td>
              <td>{gatePass.date}</td>
              <td>{gatePass.orderAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Indiamart;
